<template>
  <div></div>
</template>
<script>
export default {
  name: 'Verify',

  data() {
    return {
      input:{
        email:'',
        code:''
      },
    }
  },
  mounted:function(){
    this.verifyEmail();
  },
  methods:{
    verifyEmail() {
      this.input.email = this.$route.query.email;
      this.input.code = this.$route.query.token;
      this.$http.post(this.createApiUrlChestNut('users/verify/'), this.input).then(() =>
        {
          this.$router.push("/signin").catch(() => {});
        },() => {
          this.$router.push("/resend-verification").catch(() => {});
        });
    },
  },
}
</script>
